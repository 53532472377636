<template>
  <div style="height: 100%; overflow: hidden;">
    <!-- http://localhost:9000/ -->
    <!-- https://ctn.mmcuav.cn/tmj_fkzx -->
    <!-- https://jy.ys.mmcuav.cn/mmc_stl/#/player -->
    <!-- https://jy.ys.mmcuav.cn/tmj_fkzx -->
    <iframe
      id="iframe"
      ref="iframe"
      src="https://jy.ys.mmcuav.cn/mmc_stl/#/player"
      style="width: 100%; height:100%; border: 0"
    ></iframe>
  </div>
</template>


<script>
import { Base64 } from "js-base64";
import API from "@/api";
import { nanoid } from "nanoid";

// 航线状态对应中文
const statusMap = {
  1: "可用",
  2: "待申请",
  3: "待审批",
  4: "通过",
  5: "驳回",
};

export default {
  name: "fkzxIframe",
  data() {
    return {
      isIframeReady: false, //是否接收到iframe里的ready事件
      baseUrl: "https://test.tmj.mmcuav.cn",
      devMode: false,
      scene: null, // 场景类型 1: 无人机 2: 机库
      useSTLAirway: null, // 使用标准航线库
      account: "mmctest@admin",
      password: "test@Admin001",
      userInfo: null,
      uavTaskList: [
        /*  {
          name: "任务1",
          id: 1,
          children: [
            {
              name: "任务2",
              id: 2,
              // children: [],
              airway: {
                name: "航线名称2",
                id: 2,
              },
            },
          ],
          airway: {
            name: "航线名称1",
            id: 1,
            content:
              '{"filename":"肇庆航线20240318","line":{"baseSpeed":3},"content":[{"uuid":"1nmI-Fo18IagbcVJsia7Q","latitude":23.178153411812204,"longitude":112.57807281336807,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]},{"uuid":"9pTbBPlF8iIwbUNqusyHK","latitude":23.17783116525969,"longitude":112.57797543441967,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]},{"uuid":"s91IhN22wuaeyG-UQs0XR","latitude":23.17786413506686,"longitude":112.57824336604547,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]},{"uuid":"xS_JIl3wxQrhMPdpcjcSn","latitude":23.17820934975604,"longitude":112.5781357731637,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]}],"baseSpeed":3,"gimbalYaw":0,"gimbalPitch":0,"alt":100}',
          },
        },
        {
          name: "任务2",
          id: 2,
          children: [],
          airway: {
            name: "航线名称2",
            id: 2,
            content:
              '{"filename":"肇庆航线20240318","line":{"baseSpeed":3},"content":[{"uuid":"1nmI-Fo18IagbcVJsia7Q","latitude":23.178153411812204,"longitude":112.57807281336807,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]},{"uuid":"9pTbBPlF8iIwbUNqusyHK","latitude":23.17783116525969,"longitude":112.57797543441967,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]},{"uuid":"s91IhN22wuaeyG-UQs0XR","latitude":23.17786413506686,"longitude":112.57824336604547,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]},{"uuid":"xS_JIl3wxQrhMPdpcjcSn","latitude":23.17820934975604,"longitude":112.5781357731637,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[]}],"baseSpeed":3,"gimbalYaw":0,"gimbalPitch":0,"alt":100}',
          },
        }, */
      ],
      hangarTaskList: {
        // 常态
        normal: [
          /* 
          {
            name: "任务1",
            id: 1,
            airwayId: 105,
          },
          {
            name: "任务2",
            id: 2,
            airwayId: 106,
          }, */
        ],
        // 定时
        timed: [
          /* {
            name: "任务",
            id: 1,
            time: "2024-04-28 01:00:00", //定时与周期的时间
            status: "未执行", //任务状态
            airwayId: 105,
          }, */
        ],
        // 周期
        period: [
          /* {
                name: '任务',
                id: 1,
                time: '', //定时与周期的时间
                status: '', 任务状态
                airway: {
                  name: '航线名称',
                  id: 1
                }
              } */
        ],
      },
    };
  },
  computed: {},
  watch: {
    $route() {
      this.scene = Number(this.$route.query.scene);
    },
    isIframeReady() {
      if (this.isIframeReady) {
        this.scene = Number(this.$route.query.scene);
        this.login();
      }
    },
    devMode: {
      immediate: true,
      handler() {
        if (this.devMode) {
          this.account = "mmctest@admin";
          this.password = "test@Admin001";
          this.baseUrl = "https://test.tmj.mmcuav.cn";
        } else {
          this.account = "mmcadmin@kbt001";
          this.password = "TMJMMC@kbta0227&adm";
          this.baseUrl = "https://tmj.mmcuav.cn";
        }
        this.postIframe({
          type: "param",
          param: "devMode",
          data: this.devMode,
        });
      },
    },
    // 无人机任务列表
    uavTaskList() {
      this.postIframe({
        type: "param",
        param: "uavTaskList",
        data: this.uavTaskList,
      });
    },
    // 机库任务列表
    hangarTaskList: {
      deep: true,
      handler() {
        this.postIframe({
          type: "param",
          param: "hangarTaskList",
          data: this.hangarTaskList,
        });
      },
    },
    // 场景
    scene() {
      this.postIframe({
        type: "param",
        param: "scene",
        data: this.scene,
      });
    },
    userInfo() {
      this.postIframe({
        type: "param",
        param: "userInfo",
        data: this.userInfo,
      });
    },
    useSTLAirway() {
      this.postIframe({
        type: "param",
        param: "useSTLAirway",
        data: this.useSTLAirway,
      });
    },
  },
  async mounted() {
    window.postIframe = this.postIframe;
    window.addEventListener(
      "message",
      async (event) => {
        if (event.data.module === "MMCFlightControlCenter") {
          if (event.data.type === "ready") {
            // ready中往iframe传参数进行初始化
            console.log(event.data); // 输出子传父
            this.isIframeReady = true;
          }

          if (event.data.type === "event") {
            this.eventHandler(event.data);
          }
        }
      },
      false
    );
  },
  methods: {
    /**
     * 给iframe发送消息
     */
    postIframe(data) {
      if (!this.isIframeReady) {
        throw Error("iframe未准备好");
      }
      let iframeWindow = this.$refs.iframe.contentWindow;
      console.log("postIframe", data);
      iframeWindow.postMessage(
        {
          module: "MMCFlightControlCenter",
          ...data,
        },
        "*"
      );
    },
    /**
     * 事件处理
     */
    eventHandler({ event, data }) {
      console.log("eventHandler", data);
      switch (event) {
        case "uavChange":
          this.onUavChange(data);
          break;
        case "uavTaskAdd":
          this.onUavTaskAdd(data);
          break;

        case "uavTaskStart":
          this.onUAVTaskStart(data);
          break;

        case "hangarChange":
          this.onHangarChange(data);
          break;

        case "hangarTaskTabChange":
          this.onHangarTaskTabChange(data);
          break;

        case "hangarTaskStart":
          this.onHangarTaskStart(data);
          break;

        case "hangarTaskAdd":
          this.onHangarTaskAdd(data);
          break;

        case "hangarTaskDel":
          this.onHangarTaskDel(data);
          break;

        case "log":
          this.onLog(data);
          break;

        case "airwayPageChange":
          this.onAirwayPageChange(data);
          break;

        case "airwayGet":
          this.onAirwayGet(data);
      }
    },
    /**
     * 获取航线事件
     */
    async onAirwayGet({ airwayId }) {
      let res = await API.AIRWAY.flightLineList({
        id: airwayId,
        pageNo: 1,
        pageSize: 1,
      });
      let airway = res?.data?.data[0];
      try {
        let flightCourse = JSON.parse(airway.flightCourseJson);
        let content = flightCourse.points?.map((item) => {
          return {
            uuid: nanoid(),
            longitude: item.lon,
            latitude: item.lat,
            alt: item.alt,
            yawAngle: 0,
            pitchAngle: 0,
            speed: flightCourse.baseSpeed,
            actions: [],
          };
        });
        this.postIframe({
          type: "callback",
          event: "airwayGet",
          data: airway
            ? {
                id: airway.id,
                name: airway.flightName,
                organizationName: "",
                status: statusMap[airway.status],
                isSafe: airway.issafe,
                labelName: "",
                content: content,
              }
            : null,
        });
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * 航线分页更改
     */
    async onAirwayPageChange({ page, pageSize }) {
      console.log("航线分页更改", page, pageSize);
      let res = await API.AIRWAY.flightLineList({
        ...this.search,
        pageNo: page,
        pageSize: pageSize,
      });

      this.currentPage = res.data.pageNo;
      this.size = res.data.pageSize;
      this.total = res.data.totalCount;
      this.dataSource = res.data.data;

      this.postIframe({
        type: "callback",
        event: "airwayPageChange",
        data: {
          current: res?.data?.pageNo, // 当前页码
          records:
            res?.data?.data?.map((item) => {
              let flightCourse = JSON.parse(item.flightCourseJson);
              let content = flightCourse.points?.map((item) => {
                return {
                  uuid: nanoid(),
                  longitude: item.lon,
                  latitude: item.lat,
                  alt: item.alt,
                  yawAngle: 0,
                  pitchAngle: 0,
                  speed: flightCourse.baseSpeed,
                  actions: [],
                };
              });
              return {
                id: item.id,
                name: item.flightName,
                organizationName: "",
                status: statusMap[item.status],
                isSafe: item.issafe,
                labelName: "",
                content: content,
              };
            }) || [],
          /* [
            {
              id: 1,
              name: "模拟航线" + `第${page}页`,
              organizationName: "机构名",
              status: "可用", //空域状态
              isSafe: 1, // 安全状态1: 安全 , 0: 待确定,
              labelName: "航线标签",
              //航线数据
              content:
                '{"filename":"盐城基地拍照录像","line":{"baseSpeed":6},"content":[{"uuid":"1uamoBnUN8je_2s5O7LAI","latitude":33.326034986025924,"longitude":120.2855868204341,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[{"id":2,"label":"拍照","key":"photo","checked":true,"value":1,"unit":"张"}]},{"uuid":"0V_BPu2m3u-zsCIVKRR7v","latitude":33.32572118330467,"longitude":120.28452270932941,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[{"id":2,"label":"拍照","key":"photo","checked":true,"value":1,"unit":"张"}]},{"uuid":"vXX0IkeE-z0BjMcQEG3f1","latitude":33.32483291531692,"longitude":120.28544172335158,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[{"id":3,"label":"录像","key":"video","checked":true,"value":0,"unit":"s"}]},{"uuid":"L32qDDlAiMtyKjoaySxHW","latitude":33.32576947678917,"longitude":120.28641994886797,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[{"id":3,"label":"录像","key":"video","checked":true,"value":0,"unit":"s"}]},{"uuid":"FYnNBx-i3bHkbEgt3QCWF","latitude":33.326686239734364,"longitude":120.28572577154279,"alt":100,"yawAngle":0,"pitchAngle":0,"speed":3,"actions":[{"id":3,"label":"录像","key":"video","checked":true,"value":0,"unit":"s"}]}],"baseSpeed":6,"gimbalYaw":0,"gimbalPitch":0,"alt":100}',
            },
          ], */
          size: res?.data?.pageSize, // 当前页记录数
          total: res?.data?.totalCount, // 总记录灵
        },
      });
    },
    onLog({ keys, value }) {
      console.log("日志输出", keys, value);
    },
    /**
     * 更换无人机
     * @param data
     * @param {Object} data.uav 无人机
     * @param {Boolean} data.showPanel 是否显示数据面板
     * @param {Boolean} data.showPlayer 是否显示播放器
     */
    onUavChange({ uav, showPanel, showPlayer }) {
      console.log("更换无人机事件", uav, showPanel, showPlayer);
    },
    /**
     * 无人机通过航线创建任务
     */
    onUavTaskAdd({ airway }) {
      console.log("无人机通过航线创建任务", airway);
      let id = Date.now();
      this.uavTaskList.push({
        name: "任务" + id,
        id: id,
        airwayId: airway.id,
        airway,
      });
      this.$nextTick(() => {
        this.postIframe({
          type: "callback",
          event: "uavTaskAdd",
          data: {
            id,
          },
        });
      });
    },
    /**
     * 无人机开始任务
     */
    onUAVTaskStart({ uav, selectedTask, selectedAirway }) {
      console.log("一键任务事件", uav, selectedTask, selectedAirway);
      // alert("一键任务事件");
    },
    /**
     * 更换机库事件
     */
    onHangarChange({ hangar }) {
      console.log("更换机库事件", hangar);
    },
    /**
     * 机库任务添加事件
     * @param {Object} param
     * @param {number} param.type 1: 日常任务 2: 定时任务 3:周期任务
     * @param {Object} param.airway 航线数据
     * @param {Array} param.taskList 定时与周期任务创建的任务数组
     */
    onHangarTaskAdd({ type, airway, taskList }) {
      console.log("机库任务添加事件", type, airway, taskList);
      switch (type) {
        case 1: {
          let id = Date.now();
          this.hangarTaskList.normal.push({
            name: "任务" + id,
            id: id,
            airwayId: airway.id,
            airway,
          });
          this.$nextTick(() => {
            // 回调，参数为更新后新增的任务id, 仅日常任务有用
            this.postIframe({
              type: "callback",
              event: "hangarTaskAdd",
              data: {
                id,
              },
            });
          });

          break;
        }

        case 2: {
          let id = Date.now();
          let _taskList = taskList.map((item, i) => {
            return {
              ...item,
              id: id + String(i),
              status: "未执行",
              name: "任务" + (this.hangarTaskList.timed.length + 1),
            };
          });
          this.hangarTaskList.timed =
            this.hangarTaskList.timed.concat(_taskList);
          break;
        }

        case 3: {
          let id = Date.now();
          let _taskList = taskList.map((item, i) => {
            return {
              ...item,
              id: id + String(i),
              status: "未执行",
              name: "任务" + (this.hangarTaskList.period.length + 1),
              time: `${item.date[0]} ${item.time[0]} ${item.date[1]} ${item.time[1]}`,
            };
          });
          this.hangarTaskList.period =
            this.hangarTaskList.period.concat(_taskList);
          break;
        }
      }
    },
    /**
     * 机库任务tab更改事件
     * @param {object} param
     * @param {number} param.type  1: 日常任务 2: 定时任务 3:周期任务
     */
    onHangarTaskTabChange({ type }) {
      console.log("机库任务tab更改事件", type);
    },
    /**
     * 机库开始任务
     * @param {object} param
     * @parma {number} param.type  1: 日常任务 2: 定时任务 3:周期任务
     * @param {object} param.task 任务对象
     * @param {object} param.airway 航线信息
     */
    onHangarTaskStart({ type, hangar, task, airway }) {
      console.log("机库开始任务", type, hangar, task, airway);
    },
    /**
     * 删除机库任务
     * @param {object} param
     * @parma {number} param.type  1: 日常任务 2: 定时任务 3:周期任务
     * @param {object} param.task 任务对象
     */
    onHangarTaskDel({ type, task }) {
      console.log("删除机库任务", type, task);
      switch (type) {
        case 2:
          this.hangarTaskList.timed = this.hangarTaskList.timed.filter(
            (item) => item.id !== task.id
          );
          break;

        case 3:
          this.hangarTaskList.period = this.hangarTaskList.period.filter(
            (item) => item.id !== task.id
          );
          break;
      }
    },
    init() {
      this.useSTLAirway = true;

      API.TASK.intuitive({
        isSubtasks: 2,
        disposeStatus: 2,
        pageNum: 1,
        pageSize: 9999,
      }).then((res) => {
        this.uavTaskList = res.data.data.task
          .filter((item) => item.flightLineId)
          .map((item) => {
            return {
              name: item.taskTitle,
              id: item.id,
              airwayId: item.flightLineId,
            };
          });

        let uavTaskList = JSON.parse(JSON.stringify(this.uavTaskList));
        this.hangarTaskList = {
          // 常态
          normal: uavTaskList,
          // 定时
          timed: [],
          // 周期
          period: [],
        };
      });
    },
    async login() {
      let formData = new FormData();
      formData.append("userAccount", this.account);
      formData.append("password", Base64.encode(this.password));
      let res1 = await fetch(this.baseUrl + "/crm/login", {
        method: "post",
        headers: {
          // "Content-Type": "multipart/form-data",
        },
        body: formData,
      });
      let data1 = await res1.json();

      if (data1.code == 200) {
        await fetch(this.baseUrl + "/crm/user/platformPermission", {
          method: "get",
          headers: {
            Token: data1.data.token,
          },
        });
        this.userInfo = data1.data;
        this.init();
      }
    },
  },
};
</script>